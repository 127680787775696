import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import "./_index.scss"

const SecondaryHeader = () => {
  return (
    <section id="secondary-header">
      <Container>
        <Row>
          <Col>
            <Link to="/">
              <img
                className="img-fluid"
                src={require("../../../static/logos/site-logo.svg")}
                alt="Logo"
              />
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default SecondaryHeader
