import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import "./_index.scss"

const SignIn = () => {
  return (
    <section id="signin-section">
      <Container>
        <Row className="justify-content-center signin-form-wrapper">
          <Col lg={4} md={8} className="my-md-auto">
            <form className="form-signin">
              <Row>
                <Col className="text-center form-head-text">
                  <img
                    src={require("../../../static/logos/login-logo.svg")}
                    alt="logo"
                  />
                  <h1 className="">Welcome back!</h1>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Username *"
                    />
                  </div>

                  <div className="form-group mb-1">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password *"
                    />
                  </div>

                  <div className="form-group forgot-password-wrap">
                    <Link to="/forgot-password">Forgot password?</Link>
                  </div>
                  <button
                    className="btn btn-lg btn-block sign-in-btn"
                    type="submit"
                  >
                    Sign in
                  </button>
                </Col>
              </Row>

              <Row>
                <Col className="my-md-3 my-5 text-center sign-up-text">
                  <p>
                    Don’t have an account?{" "}
                    <Link to="/create-account">Sign up</Link>
                  </p>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row>
          <Col className="p-0 bottom-rectangle position-absolute">
            <img
              className="img-fluid w-100"
              src={require("../../../static/rectangle.svg")}
              alt="rectangle"
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default SignIn
