import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import SignIn from "../components/sign-in"
import SecondaryHeader from "../components/secondary-header"

const SignInPage = ({ data }) => {
  const metaData = data.allContentfulSignIn.nodes[0]

  return (
    <>
      <SecondaryHeader />
      <SEO title="Sign in" description={metaData.metaDescription} />
      <SignIn />
    </>
  )
}

export const query = graphql`
  {
    allContentfulSignIn {
      nodes {
        metaDescription
      }
    }
  }
`

export default SignInPage
